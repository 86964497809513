body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #4e73df;
    background-image: linear-gradient(180deg,#4e73df 10%,#224abe 100%);
    background-size: cover;
}
@import "../node_modules/bootstrap/dist/css/bootstrap.css";
@import "./assest/styles/sb-admin-2.css";

.sidebar .nav-item {
  position: relative;
  border-bottom: 1px solid rgba(255,255,255,.15);
}
.sidebar-brand-text{
  width: 180px;
  margin-top: -5px;
}
.sidebar-brand-text img{
  width: 100%;
}
.sidebar-dark .sidebar-brand{
  background: #fff;
}