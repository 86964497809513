.tutortable{
    .img-profile {
            min-width: 60px;
            max-width: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            img{
                width: 100%;
            }
    }
}